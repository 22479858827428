<template>
  <div class="service-form-part">
    <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
    <MarketSegmentField
      :init-with-value="marketSegment"
      hidden
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import {
  solarPanelsPurchaseIT,
  solarPanelsPurchaseSolarCollectorsIT,
} from 'chimera/solarPanels/service'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'
import MarketSegmentField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentField.vue'

export default {
  name: 'ServiceRadioFormPartPD6781',

  components: {
    MarketSegmentField,
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      marketSegment: this.$t('field.marketSegment.consumer'),
      serviceSelections: [
        new Selectable(
          'Pannelli solari',
          'Pannelli solari',
          solarPanelsPurchaseIT.id,
        ),
        new Selectable(
          'Scaldabagno a energia solare / Pannelli solari termici',
          'Scaldabagno a energia solare / Pannelli solari termici',
          solarPanelsPurchaseSolarCollectorsIT.id,
        ),
      ],
    }
  },
}
</script>
