<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <template v-if="experiment && experiment.isTreatment">
        <ServiceRadioFormPartPD6781 @result="onResult" @change="onChange" />
      </template>
      <template v-else-if="experiment">
        <ServiceRadioFormPart @result="onResult" @change="onChange" />
      </template>
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import ServiceRadioFormPart from 'chimera/solarPanels/components/form/parts/service/ServiceRadioFormPart.it'
import ServiceRadioFormPartPD6781 from 'chimera/solarPanels/components/form/parts/service/ServiceRadioFormPart.PD6781.it'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import Step4 from '~/pages/richieste-di-preventivi/tipo-tetto-stima-consumo-elettrico-annuale'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    ServiceRadioFormPart,
    ServiceRadioFormPartPD6781,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {{experiment: undefined}}
   */
  data() {
    return {
      experiment: undefined,
    }
  },

  /**
   * @returns {Promise<void>}
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/355
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6781',
      source: ExperimentSource.ABSMARTLY,
    })
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(Step4)
    },
  },
}
</script>
