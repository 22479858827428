<template>
  <FormModal
    title="Inserisci i tuoi dati"
    next-btn-text="Confronta ora"
    show-back-btn
  >
    <template #body>
      <ContactInformationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'I tuoi dati',
      headDescription:
        'Inserisci i tuoi dati così possiamo metterti in contatto con i fornitori di pannelli solari della tua regione più adatti a te.',
      path: '/richieste-di-preventivi/i-tuoi-dati',
      progressValue: 75,
      checkoutStep: 3,
    }
  },
}
</script>
