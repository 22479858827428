<template>
  <FormModal title="Tipo di richiesta">
    <template #body>
      <ServiceFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import ServiceFormStep from '~/components/form/steps/service/ServiceFormStep'

export default {
  components: {
    ServiceFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Tipo di progetto',
      headDescription:
        'Per favore, indica il tipo di fornitore di pannelli solari che stai cercando.',
      path: '/richieste-di-preventivi/tipo-di-progetto',
      progressValue: 25,
      checkoutStep: 1,
      experiment: undefined,
      stepTitle: 'Tipo di richiesta',
    }
  },

  /**
   *
   * @returns {Promise<void>}
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/355
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6781',
      source: ExperimentSource.ABSMARTLY,
    })

    if (this.experiment && this.experiment.isTreatment) {
      this.stepTitle = 'Tipo di progetto'
    }
  },
}
</script>
