<template>
  <FormModal title="Tipo de tetto" show-back-btn>
    <template #body>
      <RoofTypeElectricityEstimationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import RoofTypeElectricityEstimationFormStep from '~/components/form/steps/roofTypeElectricityEstimation/RoofTypeElectricityEstimationFormStep'

export default {
  components: {
    RoofTypeElectricityEstimationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Tipo di tetto e consumo annuo stimato di elettricità',
      headDescription:
        'Per favore, indica il tipo di tetto e il consumo annuo stimato di elettricità.',
      path: '/richieste-di-preventivi/tipo-tetto-stima-consumo-elettrico-annuale',
      progressValue: 50,
      checkoutStep: 2,
    }
  },
}
</script>
