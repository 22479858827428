<template>
  <div class="service-form-part">
    <MarketSegmentRadioFormPart :choices="marketSegments" v-on="$listeners" />

    <h2 class="form-modal__part-title">
      {{ $t('steps.typeOfAssignment') }}
    </h2>

    <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
  </div>
</template>

<script>
import {
  solarPanelsPurchaseIT,
  solarPanelsPurchaseSolarCollectorsIT,
} from 'chimera/solarPanels/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import MarketSegmentRadioFormPart from 'chimera/all/components/form/parts/marketSegment/MarketSegmentRadioFormPart.vue'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
    MarketSegmentRadioFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      marketSegments: [
        new Selectable(
          'Abitazione',
          'Abitazione',
          this.$t('field.marketSegment.consumer'),
        ),
        new Selectable(
          'Edificio commerciale',
          'Edificio commerciale',
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          'Impresa agricola',
          'Impresa agricola',
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          'Progetti aziendali su larga scala',
          'Progetti aziendali su larga scala',
          this.$t('field.marketSegment.corporateValue'),
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Pannelli solari',
          'Pannelli solari',
          solarPanelsPurchaseIT.id,
        ),
        new Selectable(
          'Scaldabagno a energia solare / Pannelli solari termici',
          'Scaldabagno a energia solare / Pannelli solari termici',
          solarPanelsPurchaseSolarCollectorsIT.id,
        ),
      ],
    }
  },
}
</script>
