<template>
  <EstimatedAnnualEnergyConsumptionRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import EstimatedAnnualEnergyConsumptionRadioField from 'chimera/all/components/form/fields/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'EstimatedAnnualEnergyConsumptionFormPart',

  components: {
    EstimatedAnnualEnergyConsumptionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      corporateChoices: [
        new Selectable(
          'Meno di 10.000 kWh (consumo dei consumatori)',
          'Meno di 10.000 kWh (consumo dei consumatori)',
          'Meno di 10.000 kWh (consumo dei consumatori)',
        ),
        new Selectable(
          'Tra 10.000 e 35.000 kWh',
          'Tra 10.000 e 35.000 kWh',
          'Tra 10.000 e 35.000 kWh',
        ),
        new Selectable(
          "Più di 35.000 kWh (consumo all'ingrosso)",
          "Più di 35.000 kWh (consumo all'ingrosso)",
          "Più di 35.000 kWh (consumo all'ingrosso)",
        ),
      ],

      consumerChoices: [
        new Selectable(
          '2.300 kWh (1 persona)',
          '2.300 kWh (1 persona)',
          '2.300 kWh (1 persona)',
        ),
        new Selectable(
          '3.400 kWh (2 persone)',
          '3.400 kWh (2 persone)',
          '3.400 kWh (2 persone)',
        ),
        new Selectable(
          '4.100 kWh (3 persone)',
          '4.100 kWh (3 persone)',
          '4.100 kWh (3 persone)',
        ),
        new Selectable(
          '4.600 kWh (4 persone)',
          '4.600 kWh (4 persone)',
          '4.600 kWh (4 persone)',
        ),
        new Selectable(
          '5.300 kWh (5 persone)',
          '5.300 kWh (5 persone)',
          '5.300 kWh (5 persone)',
        ),
        new Selectable(
          '5.400 kWh (6 persone)',
          '5.400 kWh (6 persone)',
          '5.400 kWh (6 persone)',
        ),
        new Selectable(
          'Più di 5.400 kWh',
          'Più di 5.400 kWh',
          'Più di 5.400 kWh',
        ),
        new Selectable(
          'Non riesco a fare una stima',
          'Non riesco a fare una stima',
          'Non riesco a fare una stima',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {Selectable[]}
     */
    choices() {
      let marketSegment = this.$store.getters['lead/getData']('market-segment')
      if (typeof marketSegment === 'object' && marketSegment[0]?.value) {
        marketSegment = marketSegment[0].value
      }

      if (marketSegment === this.$t('field.marketSegment.consumer')) {
        return this.consumerChoices
      }

      return this.corporateChoices
    },
  },
}
</script>
