<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">
          {{ cardTitle }}
        </h2>

        <span class="card__text">
          {{ cardText }}
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext">
          {{ cardSubtext }}
        </span>
      </div>
    </Header>
    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep.vue'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Confrontapannellisolari.it',
      headTitleTemplate: '%s',
      headDescription:
        'Inserisci il tuo codice postale e confronta 6 preventivi gratuiti dalle migliori aziende della tua zona. Risparmia fino al 40% ✓100% gratis!',
      path: '/',
      usps: [
        {
          title: 'Rispondi ad alcune domande',
          text: 'Tu ci dici cosa stai cercando e noi pensiamo ad individuare per te lo specialista giusto nella tua zona.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Richiedi preventivi gratuiti',
          text: 'Riceverai fino a sei preventivi da specialisti che rispondono alle tue esigenze. Puoi risparmiare fino al 40%!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Scegli lo specialista giusto',
          text: 'Abbiamo già esaminato i nostri specialisti, quindi tutto ciò che resta da fare è confrontare e scegliere.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
      cardTitle: 'Trova lo specialista nella tua zona',
      cardText:
        'Confronta i preventivi prima di decidere e risparmia fino al 40%!',
      cardSubtext: 'Confronta 6 fornitori di pannelli solari nella tua zona',
      experiment: undefined,
    }
  },
}
</script>

<style lang="scss" scoped>
.page-index .header--white--text .header__card {
  @media (min-width: 541px) {
    @apply max-w-[444px];
  }
}
</style>
